<template>
  <div>
    <b-row>
      <template v-if="questionList[questionActive].field_conditions?.[0]">
        <b-col sm="12">
          <InputSelect
            :class="['mb-2 w-25', inputClass]"
            textFloat="Choose a field value type"
            title="Value Type"
            name="type"
            isRequired
            v-model="questionList[questionActive].field_conditions[0].value"
            v-bind:options="typeOption"
            @onDataChange="numberType = 1"
            valueField="value"
            textField="name"
          />
        </b-col>
        <b-col
          sm="6"
          v-if="
            questionList[questionActive].field_conditions[0].value == 'number'
          "
          class="mt-auto pr-1"
        >
          <InputSelect
            textFloat="Choose a field type"
            title=""
            name="type"
            v-model="numberType"
            v-bind:options="numberOption"
            @onDataChange="changeNumberType"
            valueField="value"
            textField="name"
            class="mb-2"
          />
        </b-col>
        <b-col
          :class="
            questionList[questionActive].field_conditions[0].value == 'text'
              ? ''
              : 'pl-1'
          "
          :sm="
            questionList[questionActive].field_conditions[0].value == 'text'
              ? '12'
              : '6'
          "
          v-if="
            questionList[questionActive].field_conditions[0].value == 'text' ||
            numberType == 1
          "
          class="mt-auto"
        >
          <InputText
            class="text-float-normal mb-2"
            textFloat="Length"
            v-model="questionList[questionActive].field_conditions[1].value"
            placeholder="Length"
            type="number"
          />
        </b-col>
      </template>

      <b-col sm="6" v-if="numberType == 2" class="pl-1">
        <div class="d-flex align-items-center">
          <InputText
            class="mr-1 text-float-normal mb-2"
            textFloat="Min"
            v-model="questionList[questionActive].field_conditions[2].value"
            placeholder="Min"
            type="number"
          />
          <InputText
            class="ml-1 text-float-normal mb-2"
            textFloat="Max"
            v-model="questionList[questionActive].field_conditions[3].value"
            placeholder="Max"
            type="number"
          />
        </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InputText from "@/components/inputs/InputText";
import InputSelect from "@/components/inputs/InputSelect";
export default {
  name: "FieldTextDetail",
  components: {
    InputText,
    InputSelect,
  },
  props: {
    questionList: {
      required: true,
      type: Array,
    },
    questionActive: {
      required: true,
      type: Number,
    },
    inputClass: {
      required: false,
      type: String,
    },
  },
  data() {
    return {
      isLoading: true,
      isBusy: false,
      numberType: 1,
      typeOption: [
        { value: "text", name: "Character" },
        { value: "number", name: "Numberic" },
      ],
      numberOption: [
        { value: 1, name: "Length" },
        { value: 2, name: "Min-Max" },
      ],
    };
  },
  watch: {
    questionActive: {
      handler(val) {
        this.checkTypeCondition();
      },
    },
  },
  created() {
    this.checkTypeCondition();
  },
  methods: {
    changeNumberType(value) {
      if (value == 1) {
        this.questionList[this.questionActive].field_conditions[2].value = "";
        this.questionList[this.questionActive].field_conditions[3].value = "";
      } else {
        this.questionList[this.questionActive].field_conditions[1].value = "";
      }
    },
    checkTypeCondition() {
      if (
        this.questionList[this.questionActive].field_conditions?.[0].value ==
        "number"
      ) {
        if (this.questionList[this.questionActive].field_conditions[1].value) {
          this.numberType = 1;
        } else if (
          this.questionList[this.questionActive].field_conditions[2].value ||
          this.questionList[this.questionActive].field_conditions[3].value
        ) {
          this.numberType = 2;
        }
      } else {
        this.numberType = 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .text-float-normal .input-custom {
  label {
    color: #5b5b5b;
    font-size: 16px;
    font-weight: 100;
  }
}
::v-deep .custom-select {
  height: 38px;
}

::v-deep .select-custom {
  label {
    color: #5b5b5b;
    font-size: 16px;
    font-weight: 100;
  }
}
</style>
